import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import styles from "../assets/css/origins.module.scss";
import { Link } from "gatsby-plugin-intl";

const OrigenPage = () => (
	<Layout>
		<Seo title="Origen" />
		<section className={styles.origins}>
			<article className={styles.bgOrigins}>
				<div className={styles.originsWrapper}>
					<Link to="/" className="lineLink">
						<u>Volver a la home</u>
					</Link>
				</div>
				<div className={styles.titleWrapper}>
					<h2>Origen</h2>
				</div>
			</article>
			<article className={styles.originsDetails}>
				<h3>
					Todo proyecto nace de un sueño o de una idea que mueve y une a
					muchas personas. Somos Fresh Snow y esta es nuestra historia.
				</h3>
				<p>
					Desde hace años escuchamos hablar de las nuevas tecnologías, de
					como las redes sociales invadieron nuestras vidas y de como la
					virtualidad nos quitó sociabilidad. Pero lo cierto es que la
					tecnología también llegó para mejorar nuestras experiencias,
					democratizar el conocimiento y para darnos más opciones a la hora
					de tomar de decisiones.
					<br />
					<br />
					Fresh Snow es la consecuencia de muchos dias de frustración,
					cuando las condiciones no eran las esperadas o la nieve no se
					acumulaba como nos habían dicho. Es la consecuencia de tener que
					entrar a más de 15 sitios webs para decidir dónde ir a esquiar
					sin tener certezas, es la consecuencia de no encontrar un
					servicio que conecte esquiador con esquiador y nos de soluciones
					y fiabilidad acorde a los tiempos que corren.
				</p>
			</article>
			<article className={styles.originsDetailsPhotos}>
				<ul>
					<li>
						<div className={styles.img}>
							<img src="/origins/origins01.jpg" alt="" />
						</div>
						<div className={styles.text}>
							<h3>Confía en la tecnología y en las personas.</h3>
							<p>
								Si algo teníamos claro a la hora de diseñar una
								herramienta como Fresh Snow, es que no íbamos a dejar
								todo en manos de las predicciones. Por un simple motivo:
								pueden fallar. Necesitábamos poder completar la
								tecnología con una parte humana que sea capaz de
								corregir y mejorar la información, necesitábamos contar
								con expertos a pie de pistas que nos den información
								valiosa más allá del tiempo.
								<br />
								<br />
								Para esto creamos la figura del Snowgod y lo
								posicionamos como ese amigo experto que nos puede guiar
								en una toma de decisiones. A este le sumamos toda una
								comunidad interconectada, capaz de ofrecer información
								de manera colaborativa, por y para los esquiadores.
							</p>
						</div>
					</li>
					<li>
						<div className={styles.text}>
							<h3>Un algoritmo que piensa como esquiador</h3>
							<p>
								Esa fue la frase que repetimos una y otra vez, y es que
								en la alta montaña suceden fenómenos meteorológicos
								diferentes incluso en las distintas alturas de la
								estación. ¿A qué esquiador frecuente no le ha pasado el
								ir a disfrutar de un dia de nieve porque el pronóstico
								anuncia mucha nieve y al llegar se encuentra con lluvia
								en la base o la estación y accesos cerrados por fuertes
								vientos?
								<br />
								<br />
								Este tipo de cosas, constantemente, nos llenaban de
								frustración y nos hacían pensar en la pérdida de tiempo
								y dinero... Y si a esto le sumamos el hecho de que una
								nevada nocturna desaparezca por un fuerte viento
								matutino y al llegar nos esperen unos preciosos bloques
								de hielo... Ya podéis empezar a entender porqué
								decidimos crear Fresh Snow.
							</p>
						</div>
						<div className={styles.img}>
							<img src="/origins/origins02.jpg" alt="" />
						</div>
					</li>
				</ul>
			</article>
			<article className={styles.founders}>
				<div className={styles.text}>
					<h3>Nuestro manifiesto</h3>
					<p>
						Hasta la aparición de Fresh Snow, los esquiadores no contaban
						con una herramienta que realmente les conecte y les ayude a
						planificar su dia perfecto, ahorrando tiempo y dinero. Ese fue
						el motivo que nos empujó a iniciar esta aventura, primero como
						apasionados de los deportes de montaña y segundo como expertos
						en tecnología. Combinamos nuestras pasiones y nos sentamos a
						diseñar una herramienta en donde los más exigentes somos
						nosotros mismos.
						<br />
						<br />
						Creemos que las cosas hechas con el corazón tienen la
						capacidad de transmitir y contagiar al resto de las personas.
						Siempre vamos a poner el beneficio de los esquiadores como
						prioridad en la toma de decisiones, en el diseño y en la
						construcción de nuestro crecimiento, por el simple motivo que
						queremos contar siempre con vuestro apoyo y confianza, que os
						sintáis parte de este proyecto, ya que de otro modo no
						podríamos existir.
						<br />
						<br />
						Llevamos ya cuatro años de pura aventura, de anécdotas y
						momentos irrepetibles, de pruebas y errores, de aprendizajes,
						de conocer nuevos amigos y de sorprendernos de las cosas que
						conseguimos solo por perseguir un sueño. Esperamos seguir en
						este camino y así poder dejar nuestro granito de arena para
						que todos los fanáticos de la nieve puedan disfrutar del
						deporte que tanto amamos.
					</p>
				</div>
				<ul>
					<li>
						<img src="/origins/mpelaez.png" alt="Mariano Peláez" />
						<h4>Mariano Pelaez</h4>
						<p>Snowboarder, CEO & Lead Designer.</p>
					</li>
					<li>
						<img
							src="/origins/mscaramozzino.png"
							alt="Miguel Scaramozzino"
						/>
						<h4>Miguel Scaramozzino</h4>
						<p>CTO</p>
					</li>
					<li>
						<img src="/origins/kugarte.png" alt="Koldo Ugarte" />
						<h4>Koldo Ugarte</h4>
						<p>Snowboarder & CMO</p>
					</li>
				</ul>
			</article>
		</section>
	</Layout>
);

export default OrigenPage;
